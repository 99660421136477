import { Button, Typography } from "@material-ui/core"
import BouncingImage from "common/BouncingImage"
import { GlobalContext } from "common/GlobalContext"
import { Desktop, Mobile, useIsDesktop } from "common/MediaQueries"
import { SectionProps } from "common/SectionProps"
import { useContext } from "react"
import styled from "styled-components"
import {
  bouncingWrapper,
  offScreen,
  SectionContainer,
  transition,
} from "theme/shared"

const Styled = {
  SectionContainer: styled(SectionContainer)`
    padding: 80px 0 140px;
    position: relative;
    justify-content: flex-start;

    li {
      ${transition}
      ${(p) =>
        p.active &&
        `
        transform: translate3d(0,0,0);
        visibility:visible !important;
        opacity:1 !important;
    `}
    }

    ${(p) => p.theme.breakpoints.up("md")} {
      padding: 150px 160px 0;

      @media (max-height: 650px) {
        padding-top: 100px;
      }
    }
  `,
  Headline: styled(Typography).attrs({ variant: "h2" })`
    ${offScreen({ xPos: "calc(-100% - 80vh)" })}
    span {
      font-weight: 500;
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 60px;
      line-height: 36px;

      @media (max-height: 650px) {
        font-size: 45px;
      }
    }
  `,
  List: styled.ul`
    list-style: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${(p) => p.theme.breakpoints.up("md")} {
      flex-direction: initial;
      flex-wrap: wrap;
    }
  `,
  Item: styled.li<{ factor: number; shift: number; listNumber?: number }>`
    padding: 0;
    margin: 0;
    font-size: 16px;
    display: flex;
    justify-content: center;
    :not(:last-child) {
      margin-bottom: 20px;
    }
    margin-left: ${(p) => p.shift}px;
    ${(p) => offScreen({ xPos: `calc(-50% - 50vh * ${p.factor})` })}

    ${(p) => p.theme.breakpoints.up("md")} {
      width: 100%;
      align-items: stretch;
      flex-grow: 1;
    }
  `,
  Floating: styled(bouncingWrapper)`
    position: relative;
    display: flex;
    align-items: center;
    ${(p) => p.theme.breakpoints.up("md")} {
      :first-child {
        margin-right: 10px;
      }
    }
  `,
  Button: styled(Button)`
    ${offScreen({ xPos: "calc(-50% - 100vh)" })}
    margin-top: 50px;
    height: 48px;
    width: 242px;
    padding: 0;
    ${(p) => p.theme.breakpoints.down("sm")} {
      display: none;
    }
  `,

  Feature: styled(Typography).attrs({ component: "h3" })`
    margin-left: 8px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: normal;

    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 20px;
    }
  `,

  FeatureBranch: styled(Typography).attrs({ component: "span" })`
    font-weight: 500;
    font-size: 16px;
    letter-spacing: normal;
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 20px;
    }
  `,
}

const SectionServices = ({ position }: SectionProps) => {
  const { active, openMenu, prev, next } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()

  if (active !== position && prev !== position && next !== position) {
    return null
  }

  return (
    <Styled.SectionContainer active={active === position} openMenu={openMenu}>
      <Styled.Headline>
        <span>Our</span> Services
      </Styled.Headline>
      <Desktop>
        <Styled.List>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={45}
                distance={0.3}
                image={"./blue_circle.svg"}
                width={isDesktop ? "80.6px" : "41px"}
                height={isDesktop ? "80.6px" : "41px"}
              />
              <Styled.Feature>
                Native & React Native App &nbsp;
                <Styled.FeatureBranch>Development</Styled.FeatureBranch>
              </Styled.Feature>
            </Styled.Floating>

            <Styled.Floating>
              <BouncingImage
                deg={-45}
                distance={0.3}
                image={"./services/image02.svg"}
                width={isDesktop ? "72.5px" : ""}
                height={isDesktop ? "74.8px" : ""}
              />
              <Styled.Feature>
                Website &nbsp;
                <Styled.FeatureBranch>Development</Styled.FeatureBranch>
              </Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={45}
                distance={0.3}
                image={"./services/image03.svg"}
                width={isDesktop ? "68.5px" : ""}
                height={isDesktop ? "59.3px" : ""}
              />
              <Styled.Feature>
                Design Thinking & Product Strategy
              </Styled.Feature>
            </Styled.Floating>

            <Styled.Floating>
              <BouncingImage
                deg={-45}
                distance={0.3}
                image={"./services/image04.svg"}
                width={isDesktop ? "72px" : ""}
                height={isDesktop ? "73.9px" : ""}
              />
              <Styled.Feature>UI & UX</Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={45}
                distance={0.3}
                image={"./services/image05.svg"}
                width={isDesktop ? "73.1px" : ""}
                height={isDesktop ? "63.2px" : ""}
              />
              <Styled.Feature>
                Product &nbsp;
                <Styled.FeatureBranch>Management</Styled.FeatureBranch>
              </Styled.Feature>
            </Styled.Floating>
            <Styled.Floating>
              <BouncingImage
                deg={-45}
                distance={0.3}
                image={"./services/image06.svg"}
                width={isDesktop ? "71.1px" : ""}
                height={isDesktop ? "67.5px" : ""}
              />
              <Styled.Feature>
                Systems Design &nbsp;
                <Styled.FeatureBranch>Development</Styled.FeatureBranch>
              </Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
        </Styled.List>
      </Desktop>
      <Mobile>
        <Styled.List>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={45}
                distance={0.3}
                image={"./blue_circle.svg"}
                width={isDesktop ? "90.6px" : "41px"}
                height={isDesktop ? "90.6px" : "41px"}
              />
              <Styled.Feature>
                Native & React Native App &nbsp;
                <Styled.FeatureBranch>Development</Styled.FeatureBranch>
              </Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={-45}
                distance={0.3}
                image={"./services/image02.svg"}
                width={isDesktop ? "72.5px" : ""}
                height={isDesktop ? "74.8px" : ""}
              />
              <Styled.Feature>
                Website &nbsp;
                <Styled.FeatureBranch>Development</Styled.FeatureBranch>
              </Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={45}
                distance={0.3}
                image={"./services/image03.svg"}
                width={isDesktop ? "68.5px" : ""}
                height={isDesktop ? "59.3px" : ""}
              />
              <Styled.Feature>
                Design Thinking & Product Strategy
              </Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={-45}
                distance={0.3}
                image={"./services/image04.svg"}
                width={isDesktop ? "72px" : ""}
                height={isDesktop ? "73.9px" : ""}
              />
              <Styled.Feature>UI & UX</Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={45}
                distance={0.3}
                image={"./services/image05.svg"}
                width={isDesktop ? "73.1px" : ""}
                height={isDesktop ? "63.2px" : ""}
              />
              <Styled.Feature>
                Product &nbsp;
                <Styled.FeatureBranch>Management</Styled.FeatureBranch>
              </Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
          <Styled.Item factor={0} shift={0}>
            <Styled.Floating>
              <BouncingImage
                deg={-45}
                distance={0.3}
                image={"./services/image06.svg"}
                width={isDesktop ? "71.1px" : ""}
                height={isDesktop ? "67.5px" : ""}
              />
              <Styled.Feature>
                Systems Design &nbsp;
                <Styled.FeatureBranch>Development</Styled.FeatureBranch>
              </Styled.Feature>
            </Styled.Floating>
          </Styled.Item>
        </Styled.List>
      </Mobile>
    </Styled.SectionContainer>
  )
}

export default SectionServices
