import { Container } from "@material-ui/core"
import BouncingImage from "common/BouncingImage"
import { GlobalContext } from "common/GlobalContext"
import { SectionProps } from "common/SectionProps"
import { FC, useContext } from "react"
import styled from "styled-components"
import { bouncingWrapper, offScreen, SectionContainer } from "theme/shared"

const Styled = {
  SectionContainer: styled(SectionContainer)`
    z-index: 1;
    ${(p) => p.theme.breakpoints.up("md")} {
      padding: 40px 0 0;

      @media (max-height: 550px) {
        padding-top: 20px;
      }
    }
  `,

  Floating01: styled(bouncingWrapper)`
    --top: 115px;
    right: -40px;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })}

    ${(p) => p.theme.breakpoints.up("md")} {
      right: 21.2px;
      top: 120px;
      img {
        width: 200px;
        height: auto;
      }

      @media (max-height: 650px) {
        top: 105px;
        img {
          width: 150px;
        }
      }
    }
  `,
  Floating02: styled(bouncingWrapper)`
    right: 15px;

    top: 35%;
    ${offScreen({ yPos: "calc( -35vh - 100% )" })}

    ${(p) => p.theme.breakpoints.up("md")} {
      right: 244px;

      top: 27%;

      img {
        width: 73px;
        height: auto;
      }

      @media (max-height: 650px) {
        top: 22%;
        img {
          width: 40px;
        }
      }
    }
  `,
  Floating03: styled(bouncingWrapper)`
    right: -40px;
    bottom: 15%;
    ${offScreen({ yPos: "calc( -100vh + 35vh - 100% )" })};

    ${(p) => p.theme.breakpoints.up("md")} {
      right: 15px;
      bottom: 37.5px;

      img {
        width: 250px;
        height: auto;
      }

      @media (max-height: 650px) {
        right: 50px;
        bottom: 50px;

        img {
          width: 180px;
        }
      }
    } ;
  `,
  Floating04: styled(bouncingWrapper)<{ $clearBottom: boolean }>`
    left: ${(p) => (p.$clearBottom ? "-30px" : " 40px")};
    bottom: 80px;
    ${offScreen({ yPos: "calc( -100vh + 80px - 100% )" })}

    ${(p) => p.theme.breakpoints.up("md")} {
      left: 234.4px;
      bottom: 105.6px;
      img {
        width: 168px;
        height: auto;
      }

      @media (max-height: 650px) {
        bottom: 70px;
        img {
          width: 100px;
        }
      }
    }
  `,
  Floating05: styled(bouncingWrapper)`
    left: 35px;
    top: 80px;
    ${offScreen({ yPos: "calc( -100% - 100px )" })}
    ${(p) => p.theme.breakpoints.up("md")} {
      left: 193.8px;
      top: 152.8px;

      img {
        width: 130px;
        height: auto;
      }

      @media (max-height: 650px) {
        left: 135px;
        top: 130px;
        img {
          width: 100px;
        }
      }
    }
  `,
  Floating06: styled(bouncingWrapper)`
    left: -35px;
    top: 100px;
    ${offScreen({ yPos: "calc( -100% - 120px )" })}
    ${(p) => p.theme.breakpoints.up("md")} {
      left: 112.3px;
      top: 234.7px;
      img {
        width: 170px;
        height: auto;
      }

      @media (max-height: 650px) {
        left: 130px;
        top: 60%;
        img {
          width: 100px;
        }
      }
    }
  `,

  Container: styled(Container)`
    position: relative;
    z-index: 1;
  `,
}

const SectionHero: FC<SectionProps & { clearBottom?: boolean }> = ({
  position,
  clearBottom,
  children,
}) => {
  const { active, openMenu, prev, next } = useContext(GlobalContext)

  if (active !== position && prev !== position && next !== position) {
    return null
  }

  return (
    <Styled.SectionContainer active={active === position} openMenu={openMenu}>
      {children}
      <Styled.Floating01>
        <BouncingImage
          deg={180}
          image={"./blue_circle.svg"}
          width="118px"
          height="118px"
        />
      </Styled.Floating01>
      <Styled.Floating02>
        <BouncingImage deg={30} image={"./home/top-right-small.svg"} />
      </Styled.Floating02>
      <Styled.Floating03>
        <BouncingImage deg={0} image={"./home/bottom-right.svg"} />
      </Styled.Floating03>
      <Styled.Floating04 $clearBottom={clearBottom}>
        <BouncingImage deg={180} image={"./home/bottom-left.svg"} />
      </Styled.Floating04>
      <Styled.Floating06>
        <BouncingImage deg={150} image={"./home/top-left-2.svg"} />
      </Styled.Floating06>
      <Styled.Floating05>
        <BouncingImage deg={-30} image={"./home/top-left.svg"} />
      </Styled.Floating05>
    </Styled.SectionContainer>
  )
}

export default SectionHero
