import { IconButton } from "@material-ui/core"
import FacebookIcon from "common/icons/FacebookIcon"
import LinkdinIcon from "common/icons/LinkdinIcon"
import styled from "styled-components"

const Styled = {
  LinksWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: 20px;
    }
  `,
  Email: styled.a`
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.darkRoyalBlue};
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 22px;
    }
  `,
  IconsList: styled.div`
    display: flex;
    justify-content: center;
    > * {
      margin: 0 -5px;

      ${(p) => p.theme.breakpoints.up("md")} {
        svg {
          width: 35px;
          height: 35px;
        }
      }
    }
  `,
}

const LinksWrap = () => (
  <Styled.LinksWrapper>
    <Styled.Email href="mailto:info_mint@mintapp.co.il">
      info_mint@mintapp.co.il
    </Styled.Email>
    <Styled.IconsList>
      <IconButton
        href="https://www.facebook.com/Mint-446936532109191/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon />
      </IconButton>
      <IconButton
        href="https://www.linkedin.com/company/mint-marketing-innovation-technology/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkdinIcon />
      </IconButton>
    </Styled.IconsList>
  </Styled.LinksWrapper>
)

export default LinksWrap
