import { SvgIcon, SvgIconProps } from "@material-ui/core"

const LinkdinIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="26.208"
    height="26.208"
    viewBox="0 0 26.208 26.208"
  >
    <g id="Group_256">
      <g id="Group_253">
        <path
          id="Path_587"
          d="M385.958 294.3a13.1 13.1 0 1 1 13.1-13.1 13.119 13.119 0 0 1-13.1 13.1zm0-24.8a11.693 11.693 0 1 0 11.694 11.7 11.707 11.707 0 0 0-11.694-11.7z"
          fill="#011272"
          transform="translate(-372.854 -268.094)"
        />
      </g>
      <g id="Group_255" transform="translate(6.864 10.09)">
        <g id="Group_254">
          <path
            id="Path_588"
            d="M400.775 293.086v4.893a.219.219 0 0 1-.219.219h-2.526a.219.219 0 0 1-.219-.219v-4.55c0-1.2-.428-2.015-1.5-2.015a1.621 1.621 0 0 0-1.52 1.084 2.02 2.02 0 0 0-.1.722v4.759a.219.219 0 0 1-.219.219h-2.525a.218.218 0 0 1-.219-.219c.006-1.212.031-7.091.005-8.473a.218.218 0 0 1 .219-.222h2.52a.219.219 0 0 1 .219.219v1.044l-.02.029h.02v-.029a2.943 2.943 0 0 1 2.671-1.473c1.95 0 3.412 1.274 3.412 4.012zm-13.43 5.111h2.525a.219.219 0 0 0 .219-.219V289.5a.219.219 0 0 0-.219-.219h-2.525a.219.219 0 0 0-.219.219v8.476a.219.219 0 0 0 .219.224z"
            fill="#011272"
            transform="translate(-387.126 -289.074)"
          />
        </g>
      </g>
    </g>
    <g id="Group_257" transform="translate(6.67 5.993)">
      <circle id="Ellipse_145" cx="1.586" cy="1.586" r="1.586" fill="#011272" />
    </g>
  </SvgIcon>
)

export default LinkdinIcon
