import { Container, Typography } from "@material-ui/core"
import BouncingImage from "common/BouncingImage"
import { GlobalContext } from "common/GlobalContext"
import { useIsDesktop } from "common/MediaQueries"
import ResponsiveImage from "common/ResponsiveImage"
import { SectionProps } from "common/SectionProps"
import { useContext } from "react"
import styled from "styled-components"
import SwiperCore, { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import { Swiper } from "swiper/react"
import {
  SectionContainer,
  bouncingWrapper,
  offScreen,
  transition,
} from "theme/shared"
import { employees } from "./employees"
SwiperCore.use([Pagination])

const Styled = {
  SectionContainer: styled(SectionContainer)`
    display: flex;
    padding-top: 120px;
    justify-content: flex-start;
    background-color: ${(p) => p.active && `#011272`};
    li {
      ${transition}
      ${(p) =>
        p.active &&
        `
          transform:rotate3d(1,0,0,0) !important;
          visibility:visible !important;
          opacity:1 !important;
      `}
      .swiper {
        display: flex;
      }
      z-index: 10;
    }
    ${(p) => p.theme.breakpoints.down("sm")} {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      gap: 15px;
    }

    ${(p) =>
      !p.active &&
      ` .swiper-pagination-bullet{
        display:none;
    }`}
  `,
  Headline: styled(Typography).attrs({ variant: "h2" })`
    ${offScreen({ xPos: "calc(-100% - 80vh)" })}
    height: 100px;
    color: #ffffff;
    font-weight: 500;
    height: auto;
    margin-bottom: 20px;
    margin-top: -50px;
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 60px;
      line-height: 36px;
      margin-bottom: 30px;
      margin-top: 0;
    }
  `,
  Container: styled(Container)`
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: auto;
      margin-bottom: auto;
    }
  `,
  ClientsList: styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    list-style: none;
    padding: 0;
    width: 100%;
    justify-content: space-between;
    gap: 20px 0;
    padding: 0 10px;
    ${(p) => p.theme.breakpoints.up("md")} {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 20px;
      margin: 0;
      list-style: none;
      padding: 0;
      width: 100%;
      gap: 30px 0;
    }
    ${(p) => p.theme.breakpoints.down("sm")} {
      line-height: 1.1;
    }
  `,
  ClientItem: styled.li`
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    transform: rotate3d(1, 0, 0, 90deg);
    opacity: 0;
    visibility: hidden;
    width: auto;
    ${(p) => p.theme.breakpoints.up("md")} {
      width: 100%;
    }
    ${(p) => p.theme.breakpoints.down("sm")} {
      width: 33%;
    }
  `,
  ClientImage: styled.div`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: relative;
    border: solid 1px #ffffff;
    margin-bottom: 8px;
    overflow: hidden;
    ${(p) => p.theme.breakpoints.up("md")} {
      width: 80px;
      height: 80px;
      border-radius: 40px;
    }
  `,
  Name: styled.span`
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    ${(p) => p.theme.breakpoints.down("sm")} {
      white-space: normal;
    }
  `,
  Role: styled.span`
    ${(p) => p.theme.breakpoints.down("sm")} {
      font-size: 13px;
      margin-top: 5px;
      padding: 0 5px;
    }
  `,
  Swiper: styled(Swiper)`
    width: 100%;
    padding-bottom: 50px;
    height: 100%;
    .swiper-slide {
      display: flex;
      flex-wrap: wrap;
      gap: 30px 11px;
    }
    .swiper-pagination {
      bottom: 70px;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: white;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        background-color: #e20e87;
      }
    }
  `,
  Floating01: styled(bouncingWrapper)`
    --top: 150px;
    right: -50px;
    top: var(--top);
    ${offScreen({ yPos: `calc( -100% - var(--top) )` })}
    ${(p) => p.theme.breakpoints.up("md")} {
      right: -70px;
      z-index: 5;
      --top: 300px;
    }
  `,
  Floating02: styled(bouncingWrapper)`
    --top: 320px;
    left: -40px;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })};
    ${(p) => p.theme.breakpoints.up("md")} {
      --top: 320px;
    }
  `,
  Floating03: styled(bouncingWrapper)`
    --top: 0px;
    right: 130px;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })}

    ${(p) => p.theme.breakpoints.up("md")} {
      --top: 700px;
      left: 350px;
      right: auto;
    }
  `,
  Floating04: styled(bouncingWrapper)`
    --top: 480px;
    right: -12px;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })}
    ${(p) => p.theme.breakpoints.up("md")} {
      --top: 130px;
      right: 300px;
    }
  `,
  Floating05: styled(bouncingWrapper)`
    --top: 590px;
    right: -62px;
    top: var(--top);
    ${offScreen({ yPos: "calc( -100% - var(--top) )" })}
    ${(p) => p.theme.breakpoints.up("md")} {
      --top: 710px;
      right: 250px;
    }
  `,
}

const SectionOurEmployees = ({ position }: SectionProps) => {
  const { active, openMenu, prev, next } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()
  // useEffect(() => {
  //   const slides = []
  //   for (let i = 0; i < employees.length; i += 6) {
  //     const slideItems = employees.slice(i, i + 6)
  //     slides.push(slideItems)
  //   }
  //   setSlidesToDisplay(slides)
  // }, [])
  if (active !== position && prev !== position && next !== position) {
    return null
  }

  return (
    <Styled.SectionContainer active={active === position} openMenu={openMenu}>
      <Styled.Headline>
        Meet our <b>Leadership Team</b>
      </Styled.Headline>
      {/* <Desktop> */}
      <Styled.Container>
        <Styled.ClientsList>
          {employees.map((item, index) => (
            <Styled.ClientItem key={index}>
              <Styled.ClientImage>
                <ResponsiveImage image={item.image} alt={item.name} />
              </Styled.ClientImage>
              <Styled.Name>{item.name}</Styled.Name>

              <Styled.Role>{item.role}</Styled.Role>
            </Styled.ClientItem>
          ))}
        </Styled.ClientsList>
      </Styled.Container>
      {/* </Desktop> */}
      {/* <Mobile> */}

      {/* <Styled.Swiper pagination={true}>
          {setSlidesToDisplay?.map(slideItems)}
          <SwiperSlide>
            <Styled.ClientsList>
              {employees.slice(0, 6).map((item, index) => (
                <Styled.ClientItem key={index}>
                  <Styled.ClientImage>
                    <ResponsiveImage image={item.image} alt={item.name} />
                  </Styled.ClientImage>
                  <Styled.Name>{item.name}</Styled.Name>

                  <Styled.Role>{item.role}</Styled.Role>
                </Styled.ClientItem>
              ))}
            </Styled.ClientsList>
          </SwiperSlide>
          <SwiperSlide>
            <Styled.ClientsList>
              {employees.slice(7).map((item, index) => (
                <Styled.ClientItem key={index}>
                  <Styled.ClientImage>
                    <ResponsiveImage image={item.image} alt={item.name} />
                  </Styled.ClientImage>
                  <Styled.Name>{item.name}</Styled.Name>

                  <Styled.Role>{item.role}</Styled.Role>
                </Styled.ClientItem>
              ))}
            </Styled.ClientsList>
          </SwiperSlide>
        </Styled.Swiper> */}
      {/* </Mobile> */}
      <Styled.Floating01>
        <BouncingImage
          deg={30}
          image={"./contact/contact-left.svg"}
          width={isDesktop ? "115px" : "87px"}
          height={isDesktop ? "115px" : "87px"}
        />
      </Styled.Floating01>
      <Styled.Floating02>
        <BouncingImage
          deg={180}
          image={"./employees/employees-left.svg"}
          width={isDesktop ? "184px" : "92px"}
          height={isDesktop ? "184px" : "92px"}
        />
      </Styled.Floating02>
      <Styled.Floating03>
        <BouncingImage
          deg={180}
          image={"./employees/employees-top-cross.svg"}
          width={isDesktop ? "28px" : "19px"}
          height={isDesktop ? "28px" : "19px"}
        />
      </Styled.Floating03>
      <Styled.Floating04>
        <BouncingImage
          deg={150}
          image={"./employees/employees-right-cross.svg"}
          width={"28px"}
          height={"28px"}
        />
      </Styled.Floating04>
      <Styled.Floating05>
        <BouncingImage
          deg={150}
          image={"./cellcom/cellcom-right.svg"}
          width={isDesktop ? "185px" : "120px"}
          height={isDesktop ? "185px" : "120px"}
        />
      </Styled.Floating05>
    </Styled.SectionContainer>
  )
}

export default SectionOurEmployees
