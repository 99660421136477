import { SvgIcon, SvgIconProps } from "@material-ui/core"

const FacebookIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="26.252"
    height="26.252"
    viewBox="0 0 26.252 26.252"
  >
    <g>
      <path
        fill="#011272"
        d="M385.934 229.2a13.126 13.126 0 1 1 13.126-13.126 13.141 13.141 0 0 1-13.126 13.126zm0-24.842a11.716 11.716 0 1 0 11.716 11.716 11.729 11.729 0 0 0-11.716-11.717z"
        transform="translate(-372.808 -202.947)"
      />
    </g>
    <g>
      <path
        fill="#011272"
        fillRule="evenodd"
        d="M394.083 221.811v7.607a.2.2 0 0 0 .2.2h2.825a.2.2 0 0 0 .2-.2v-7.732h2.048a.2.2 0 0 0 .2-.181l.2-2.33a.2.2 0 0 0-.2-.215H397.3v-1.652a.7.7 0 0 1 .7-.7h1.578a.2.2 0 0 0 .2-.2v-2.329a.2.2 0 0 0-.2-.2h-2.667a2.834 2.834 0 0 0-2.834 2.834v2.245h-1.407a.2.2 0 0 0-.2.2v2.33a.2.2 0 0 0 .2.2h1.413z"
        transform="translate(9.457 5.259) translate(-392.472 -213.881)"
      />
    </g>
  </SvgIcon>
)

export default FacebookIcon
