import styled from "styled-components"
import { transition } from "theme/shared"

const size = "150vh"

export type ColoredBackgroundCircleProps = {
  color: string
  top: string
  left?: string
  right?: string
  scale?: number
  origin?: "top" | "bottom"
}

export const ColoredBackgroundCircle = styled.div<ColoredBackgroundCircleProps>`
  position: absolute;
  background-color: ${(p) => p.color};
  width: ${size};
  height: ${size};
  border-radius: 50%;
  top: ${(p) => p.top};
  left: ${(p) => p.left ?? `calc(50% - ${size} / 2)`};
  right: ${(p) => p.right ?? `calc(50% - ${size} / 2)`};
  transform: scale(${(p) => p.scale ?? `1`});
  transform-origin: ${(p) => p.origin ?? "top"};

  ${transition}
  z-index:-1;
`
