import { Button, Typography } from "@material-ui/core"
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite"
import { Desktop, Mobile } from "common/MediaQueries"
import dynamic from "next/dynamic"
import React, { useState } from "react"
import styled from "styled-components"
import { offScreen } from "theme/shared"

const VideoDialog = dynamic(() => import("Components/VideoDialog"))

const Styled = {
  Headline: styled(Typography).attrs({ variant: "h1" })`
    ${offScreen({ yPos: "calc(-50% - 50vh)" })}
    line-height: 1.4;
  `,
  BoldHeadline: styled.div`
    font-weight: bold;
    font-size: 25px;
    margin-top: 5px;

    ${(p) => p.theme.breakpoints.up("md")} {
      div {
        margin: 0 69.5px 0;
        font-size: 38px;
        font-weight: normal;
      }
      font-size: 70px;
    }
  `,

  Button: styled(Button).attrs({ variant: "outlined" })`
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    ${offScreen({ yPos: "calc(-50% - 50vh)" })}

    svg {
      font-size: 40px !important;
      fill: ${(p) => p.theme.colors.hotPink};
    }
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 24px;
      border-radius: 31px;
      width: 275px;
      height: 62px;
      padding: 5px;
    }
  `,
}

const HeroHomepage = () => {
  const [openVideo, setOpenVideo] = useState(false)

  return (
    <>
      <Mobile>
        <Styled.Headline>
          <Styled.BoldHeadline>
            We make products <br /> that grow your business
          </Styled.BoldHeadline>
          &lt;and users actually use&gt;
        </Styled.Headline>
      </Mobile>
      <Desktop>
        {/* <Styled.Container> */}
        <Styled.Headline>
          <Styled.BoldHeadline>
            <div>BUILDING YOUR COMPANY</div>A WINNING PRODUCT
          </Styled.BoldHeadline>
          {/* </Styled.Container> */}
        </Styled.Headline>
      </Desktop>
      <Styled.Button
        variant="outlined"
        startIcon={<PlayCircleFilledWhiteIcon />}
        onClick={() => setOpenVideo(true)}
      >
        Play to know us
      </Styled.Button>
      {openVideo && (
        <VideoDialog open={openVideo} onClose={() => setOpenVideo(false)} />
      )}
    </>
  )
}

export default HeroHomepage
