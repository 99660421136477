import { FC } from "react"
import styled, { css, keyframes } from "styled-components"

type BouncingImageProps = {
  deg: number
  image?: string
  /**
   * Between 0 to 1
   * @default 1
   **/
  distance?: number
  width?: string
  height?: string
}

const bounceFloat = (x, y) => keyframes`
   0%   {transform:translate3d(0,0,0)}
  100%  {transform:translate3d(${x * 2}px,${y * 2}px,0)}
`
const bounceCss = (x, y) => css`
  position: relative;
  left: ${x * -1}px;
  top: ${y * -1}px;
  animation: ${bounceFloat(x, y)} 2.5s cubic-bezier(0.6, 0, 0.4, 1) 1s infinite
    alternate;
`
const Styled = {
  image: styled.img<{ x: number; y: number }>`
    ${(p) => bounceCss(p.x, p.y)};
  `,
  Wrapper: styled.div<{ x: number; y: number }>`
    ${(p) => bounceCss(p.x, p.y)}
  `,
}

const BouncingImage: FC<BouncingImageProps> = ({
  deg,
  image,
  distance = 1,
  width,
  height,
  children,
}) => {
  const radius = 10 * distance

  const x = radius * Math.sin((Math.PI * 2 * deg) / 360)
  const y = radius * Math.cos((Math.PI * 2 * deg) / 360)
  if (!image) {
    return (
      <Styled.Wrapper
        x={Math.round(x * 100) / 100}
        y={Math.round(y * 100) / 100}
      >
        {children}
      </Styled.Wrapper>
    )
  } else {
    return (
      <Styled.image
        src={image}
        alt=""
        x={Math.round(x * 100) / 100}
        y={Math.round(y * 100) / 100}
        width={width}
        height={height}
      />
    )
  }
}

export default BouncingImage
