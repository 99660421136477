import Image from "next/image"
import React from "react"

type ResponsiveImageProps = {
  image: string
  alt: string
  objectPosition?: string
}

const ResponsiveImage = ({
  image,
  alt,
  objectPosition,
}: ResponsiveImageProps) => {
  return (
    <Image
      objectFit="contain"
      layout="fill"
      src={`${image}@3x.png`}
      alt={alt}
      objectPosition={objectPosition}
    />
  )
}

export default ResponsiveImage
