import { Button, Chip, Typography } from "@material-ui/core"
import BouncingImage from "common/BouncingImage"
import { GlobalContext } from "common/GlobalContext"
import { Desktop, Mobile, useIsDesktop } from "common/MediaQueries"
import ProjectsCssTransition, {
  TRANSITION_CLASS,
  fadeTransition,
} from "common/ProjectsCssTransition"
import ResponsiveImage from "common/ResponsiveImage"
import { SectionProps } from "common/SectionProps"
import { useContext } from "react"
import styled from "styled-components"
import {
  SectionContainer,
  bouncingWrapper,
  offScreen,
  transition,
} from "theme/shared"
import {
  BouncingIcon,
  ButtonColors,
  ServicesColors,
  sections,
} from "./sections"

const Styled = {
  SectionContainer: styled(SectionContainer)`
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-right: 25px;
    padding-left: 25px;
    justify-content: space-evenly;

    ${(p) => p.theme.breakpoints.up("md")} {
      flex-direction: row;
      justify-content: space-between;
      padding: 100px 60px 0 187px;

      @media (max-height: 650px) {
        padding: 80px 30px 0 140px;
      }
      @media (max-width: 1100px) {
        padding: 80px 30px 0 100px;
      }
    }

    .deviceWrapper {
      ${(p) =>
        p.active &&
        `
        transform: rotate(0deg) !important;
        visibility:visible !important;
        opacity:1 !important;
    `}
    }
  `,
  LogoDescriptionWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30%;

    ${(p) => p.theme.breakpoints.up("md")} {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 35%;
      text-align: left;
      align-items: flex-start;
    }
  `,
  Wrapper: styled.div``,
  LogoWrapper: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    padding-top: 15px;
    flex-shrink: 0;
    height: 70px;
    ${(p) => p.theme.breakpoints.up("md")} {
      justify-content: flex-start;
      width: 250px;
      height: 130px;
      padding-bottom: 0;
      padding-top: 0;
    }
  `,
  Logo: styled.div`
    ${transition}
    position: relative;
    width: 100%;
    height: 100%;
    ${(p) => p.theme.breakpoints.up("md")} {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: left bottom;
    }
  `,
  Description: styled(Typography)<{ textColor?: string }>`
    font-size: 14px;
    font-weight: 600;
    color: ${(p) => p.textColor ?? p.theme.colors.darkRoyalBlue};
    ${transition}
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: 37px;
      font-size: 16px;
      width: 371px;
    }
  `,
  Services: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-left: -15px;

    ${(p) => p.theme.breakpoints.up("md")} {
      justify-content: flex-start;
    }

    ${(p) => p.theme.breakpoints.up("md")} {
      margin-right: 0;
      margin-left: 0;
      margin-top: 30px;
    }
  `,
  Chip: styled(Chip)<{ colors: ServicesColors }>`
    color: ${(p) => p.colors?.textColor};
    background-color: ${(p) => p.colors?.color};
    font-size: 14px;
    font-weight: bold;
    margin: 2px;
    height: 28px;
    ${fadeTransition}
  `,
  DeviceWrapper: styled.div<{ deg: number }>`
    transform: rotate(-75deg);
    visibility: hidden;
    opacity: 0;

    .rotationWrap {
      ${transition};
      transform: rotate(${(p) => p.deg}deg);
      position: relative;
      &:before {
        content: "";
        height: 61%;
        width: 20%;
        top: 25%;
        box-shadow: -25px -5px 40px 20px rgb(29 0 27 / 50%);
        left: 34%;
        display: block;
        position: absolute;
        z-index: -1;
        transform: rotate(-40deg);
      }
    }
  `,
  Device: styled.div`
    ${fadeTransition}
    position:relative;
    width: 220px;
    aspect-ratio: 239/338.7;
    ${(p) => p.theme.breakpoints.up("md")} {
      width: 500px;
    }
  `,
  Button: styled(Button).attrs({ variant: "contained", component: "a" })<{
    colors: ButtonColors
    target: string
    rel: string
    $desktop: boolean
  }>`
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: 30px;
      height: 48px;
    }
    color: ${(p) =>
      p.$desktop ? p.colors?.textColor[1] : p.colors?.textColor[0]};
    background-color: ${(p) =>
      p.$desktop ? p.colors?.color[1] : p.colors?.color[0]};
    border-color: ${(p) =>
      p.$desktop ? p.colors?.color[1] : p.colors?.color[0]};
    ${fadeTransition}

    &:focus,&:hover {
      color: ${(p) => p.colors?.textColor} !important;
      background-color: ${(p) => p.colors?.color} !important;
      border-color: ${(p) => p.colors?.color} !important;
    }
  `,
  BouncingWrapper: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    ${offScreen({ yPos: "-50%" })};
    pointer-events: none;
  `,
  BouncingTransition: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &.${TRANSITION_CLASS}-enter .floatingWrap {
      opacity: 0;
      &.right {
        transform: translate3d(100px, 0, 0);
      }
      &:not(.right) {
        transform: translate3d(-100px, 0, 0);
      }
    }

    &.${TRANSITION_CLASS}-enter-active .floatingWrap {
      opacity: 1;
      ${transition};
      &.right {
        transform: translate3d(0, 0, 0);
      }
      &:not(.right) {
        transform: translate3d(0, 0, 0);
      }
    }

    &.${TRANSITION_CLASS}-exit .floatingWrap {
      opacity: 1;
      &.right {
        transform: translate3d(0, 0, 0);
      }
      &:not(.right) {
        transform: translate3d(0, 0, 0);
      }
    }

    &.${TRANSITION_CLASS}-exit-active .floatingWrap {
      opacity: 0;
      &.right {
        transform: translate3d(100px, 0, 0);
      }
      &:not(.right) {
        transform: translate3d(-100px, 0, 0);
      }
      ${transition};
    }
  `,
  Floating: styled(bouncingWrapper)<{
    bouncingIcon: BouncingIcon
    $desktop: boolean
  }>`
    ${(p) => p.bouncingIcon.top && `top:${p.bouncingIcon.top};`}
    ${(p) =>
      p.$desktop
        ? p.bouncingIcon.left[1] && `left:${p.bouncingIcon.left[1]};`
        : p.bouncingIcon.left[0] && `left:${p.bouncingIcon.left[0]};`}
    ${(p) => p.bouncingIcon.bottom && `bottom:${p.bouncingIcon.bottom};`}
    ${(p) =>
      p.$desktop
        ? p.bouncingIcon.right[1] && `right:${p.bouncingIcon.right[1]};`
        : p.bouncingIcon.right[0] && `right:${p.bouncingIcon.right[0]};`}
    ${transition};
  `,
}

const SectionProjects = ({ position }: SectionProps) => {
  const { active, openMenu, prev, next } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()

  const projectsCount = sections.filter((s) => s.product).length
  const lastProject = position + projectsCount - 1

  const projectIndex =
    active >= position
      ? active > lastProject
        ? lastProject
        : active
      : position

  const sectionData = sections[projectIndex]

  const keepAlive = (index: number) => {
    return index >= position && index <= lastProject
  }

  if (!keepAlive(active) && !keepAlive(prev) && !keepAlive(next)) {
    return null
  }

  return (
    <Styled.SectionContainer
      active={active >= position && active <= lastProject}
      openMenu={openMenu}
    >
      {/* logo */}
      <Styled.LogoDescriptionWrapper>
        <Styled.LogoWrapper>
          <Styled.Logo>
            <ResponsiveImage
              objectPosition={isDesktop ? "left bottom" : null}
              image={sectionData.product?.logo}
              alt={sectionData.product?.name}
            />
          </Styled.Logo>
        </Styled.LogoWrapper>

        {/* description */}
        <Styled.Wrapper>
          <Styled.Description textColor={sectionData.product?.textColor}>
            {sectionData.product?.description}
          </Styled.Description>
        </Styled.Wrapper>

        {/* services */}
        <Styled.Services>
          {sectionData.product?.services.map((item, index) => (
            <Styled.Chip
              key={index}
              label={item}
              colors={sectionData.product?.servicesColor}
            />
          ))}
        </Styled.Services>
        <Desktop>
          <Styled.Button
            href={sectionData.product?.link}
            target="_blank"
            rel="noopener noreferrer"
            colors={sectionData.buttonColors}
            $desktop={isDesktop}
          >
            Go to {sectionData.product?.platform === "web" ? "site" : "app"}
          </Styled.Button>
        </Desktop>
      </Styled.LogoDescriptionWrapper>

      {/* device */}
      <Styled.DeviceWrapper
        deg={sectionData.product?.imageDeg}
        className="deviceWrapper"
      >
        <BouncingImage deg={0} distance={0.5}>
          <div className="rotationWrap">
            <Styled.Device>
              <ResponsiveImage
                image={sectionData.product?.image}
                alt={sectionData.product?.name}
              />
            </Styled.Device>
          </div>
        </BouncingImage>
      </Styled.DeviceWrapper>

      {/* bouncing images */}
      <Styled.BouncingWrapper>
        <ProjectsCssTransition
          position={position}
          activeSection={(section) => (
            <Styled.BouncingTransition>
              {section.bouncingIcons?.map((icon, index) => (
                <Styled.Floating
                  key={index}
                  bouncingIcon={icon}
                  className={`floatingWrap ${icon.right ? "right" : ""}`}
                  $desktop={isDesktop}
                >
                  <BouncingImage
                    deg={icon.deg}
                    image={icon.image}
                    width={isDesktop ? icon.width[1] : icon.width[0]}
                    height={isDesktop ? icon.height[1] : icon.height[0]}
                  />
                </Styled.Floating>
              ))}
            </Styled.BouncingTransition>
          )}
        />
      </Styled.BouncingWrapper>

      {/* button on mobile */}
      <Mobile>
        <Styled.Wrapper>
          <Styled.Button
            href={sectionData.product?.link}
            target="_blank"
            rel="noopener noreferrer"
            colors={sectionData.buttonColors}
            $desktop={isDesktop}
          >
            Go to {sectionData.product?.platform === "web" ? "site" : "app"}
          </Styled.Button>
        </Styled.Wrapper>
      </Mobile>
    </Styled.SectionContainer>
  )
}

export default SectionProjects
