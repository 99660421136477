import { Typography } from "@material-ui/core"
import { GlobalContext } from "common/GlobalContext"
import ResponsiveImage from "common/ResponsiveImage"
import { SectionProps } from "common/SectionProps"
import { useContext } from "react"
import styled from "styled-components"
import SwiperCore, { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"

import { useIsDesktop } from "common/MediaQueries"
import { offScreen, SectionContainer, transition } from "theme/shared"
import { clients } from "./clients"

SwiperCore.use([Pagination])

const StyledSectionContainer = styled(SectionContainer)`
  display: flex;
  padding: 120px 0 70px;

  li {
    ${transition}
    ${(p) =>
      p.active &&
      `
        transform:rotate3d(1,0,0,0); !important;
        visibility:visible !important;
        opacity:1 !important;
    `}
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    gap: 30px;
  }

  z-index: 20;
`

const StyledHeadline = styled(Typography).attrs({ variant: "h2" })`
  ${offScreen({ xPos: "calc(-100% - 80vh)" })}
  height: 100px;
  span {
    font-weight: 500;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 40px;
    font-size: 60px;
    line-height: 36px;
  }
`
const StyledClientsList = styled.div`
  ${offScreen({ xPos: "calc(100% + 80vh)" })}
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  padding: 0;
  width: 100%;

  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    justify-content: center;
    margin: 0;
    list-style: none;
    padding: 0;
  }

  .swiper {
    display: flex;
  }
`

const StyledSwiper = styled(Swiper)`
  width: 100%;
  /* max-width: 510px; */
  padding-bottom: 50px;

  .swiper-wrapper {
    @media (min-width: 1024px) {
      width: 100%;
      transform: none !important;
      justify-content: center;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    pointer-events: all;

    ${(p) => p.theme.breakpoints.up("md")} {
      max-width: 445px;
      /* margin: 0 10px; */
    }
  }

  ${(p) => p.theme.breakpoints.down("sm")} {
    max-width: 100%;

    .swiper-slide-next {
      margin-left: calc((((100% - 318px) / 2) + 10px) * -1);
    }

    .swiper-slide-prev {
      position: relative;
      left: calc((((100% - 318px) / 2) + 10px));
    }
  }

  .swiper-pagination {
    bottom: 20px;
    width: 100%;

    ${(p) => p.theme.breakpoints.down("sm")} {
      bottom: 10px;
    }
    @media (min-width: 1024px) {
      display: none;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: white;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: #011272;
    }
  }
`

const StyledClientItem = styled.li`
  width: 80px;
  height: 77px;
  /* width: 72px;
  height: 68px; */
  background: white;
  border-radius: 10px;
  overflow: hidden;

  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 72px;
    height: 68px;
  }
`

const StyledClientImage = styled.div`
  position: absolute;
  width: 72px;
  height: 70px;
  top: 4px;
  left: 4px;
  /* width: 64px;
  height: 60px;
  top: 4px;
  left: 4px; */

  img {
    border-radius: 10px;
  }

  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 64px;
    height: 60px;
    top: 4px;
    left: 4px;
  }
`

const StyledClientsContainer = styled.div<{ $secondSlide?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 318px;
  height: 100%;
  gap: 30px 10px;
  ${(p) => p?.$secondSlide && ""}

  ${(p) => p.theme.breakpoints.up("md")} {
    width: 100%;
    gap: 10px 30px;
  }
`

const SectionClients = ({ position }: SectionProps) => {
  const { active, openMenu, prev, next } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()

  if (active !== position && prev !== position && next !== position) {
    return null
  }

  const slidesPerView = isDesktop ? "auto" : 1 // isDesktop ? 1 : 1.16

  return (
    <StyledSectionContainer active={active === position} openMenu={openMenu}>
      <StyledHeadline>
        <span>Our</span> Clients
      </StyledHeadline>
      <StyledClientsList>
        <StyledSwiper
          slidesPerView={slidesPerView}
          pagination={true}
          spaceBetween={0}
          centeredSlides={isDesktop}
          watchOverflow
        >
          <SwiperSlide>
            <StyledClientsContainer>
              {clients.slice(0, 16).map((item, index) => (
                <StyledClientItem key={index}>
                  <a href={item.link}>
                    <StyledClientImage>
                      <ResponsiveImage image={item.image} alt={item.name} />
                    </StyledClientImage>
                  </a>
                </StyledClientItem>
              ))}
            </StyledClientsContainer>
          </SwiperSlide>
          <SwiperSlide>
            <StyledClientsContainer $secondSlide={true}>
              {clients.slice(16).map((item, index) => (
                <StyledClientItem key={index}>
                  <a href={item.link}>
                    <StyledClientImage>
                      <ResponsiveImage image={item.image} alt={item.name} />
                    </StyledClientImage>
                  </a>
                </StyledClientItem>
              ))}
            </StyledClientsContainer>
          </SwiperSlide>
        </StyledSwiper>
      </StyledClientsList>
    </StyledSectionContainer>
  )
}

export default SectionClients
