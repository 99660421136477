import { SvgIcon, SvgIconProps } from "@material-ui/core"
import { GlobalContext } from "common/GlobalContext"
import { useIsDesktop } from "common/MediaQueries"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import colors from "theme/colors"
import { transition } from "theme/shared"

const Styled = {
  SvgIcon: styled(SvgIcon)`
    .transition {
      fill: currentColor;
      ${transition};
    }
  `,
}

const NextSectionButton = (props: SvgIconProps) => {
  const [fill, setFill] = useState(colors.darkBlue)
  const { activeSection } = useContext(GlobalContext)
  const isDesktop = useIsDesktop()

  useEffect(() => {
    setFill(
      isDesktop ? activeSection.arrowColor[1] : activeSection.arrowColor[0]
    )
  }, [activeSection])

  return (
    <Styled.SvgIcon {...props} viewBox="0 0 43 43" width="43" height="43">
      <g fill="none">
        <g>
          <path
            d="M21.5 0A21.5 21.5 0 1 1 0 21.5 21.5 21.5 0 0 1 21.5 0z"
            transform="translate(-159 -594) translate(159 594)"
          />
          <path
            className="transition"
            style={{ color: fill }}
            d="M21.5 2c-2.633 0-5.187.515-7.59 1.532-2.321.982-4.407 2.388-6.199 4.18-1.791 1.79-3.197 3.877-4.18 6.198C2.516 16.313 2 18.867 2 21.5s.515 5.187 1.532 7.59c.982 2.321 2.388 4.407 4.18 6.199 1.79 1.791 3.877 3.197 6.198 4.18C16.313 40.484 18.867 41 21.5 41s5.187-.515 7.59-1.532c2.321-.982 4.407-2.388 6.199-4.18 1.791-1.79 3.197-3.877 4.18-6.198C40.484 26.687 41 24.133 41 21.5s-.515-5.187-1.532-7.59c-.982-2.321-2.388-4.407-4.18-6.199-1.79-1.791-3.877-3.197-6.198-4.18C26.687 2.516 24.133 2 21.5 2m0-2C33.374 0 43 9.626 43 21.5S33.374 43 21.5 43 0 33.374 0 21.5 9.626 0 21.5 0z"
            transform="translate(-159 -594) translate(159 594)"
          />
        </g>
        <path
          className="transition"
          style={{ stroke: fill }}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
          d="M-2713 2229l6 7zm6-7l-6 7z"
          transform="translate(-159 -594) rotate(-90 -2070.5 -22.5)"
        />
      </g>
    </Styled.SvgIcon>
  )
}

export default NextSectionButton
