export type Client = {
  name: string
  image: string
  link?: string
}

export const clients: Client[] = [
  {
    name: "Amot",
    image: "/clients/amot-logo-client",
    link: "#amot",
  },
  {
    name: "Strauss",
    image: "/clients/strauss--logo-clients",
    link: "#strauss",
  },
  {
    name: "Cellcom",
    image: "/clients/cellcom-logo-client",
    link: "#cellcom",
  },
  {
    name: "Yellow",
    image: "/clients/yellow-logo-clients",
    link: "#yellow",
  },
  {
    name: "AllInn",
    image: "/clients/allin-logo-client",
    link: "#allinn",
  },
  {
    name: "Tami",
    image: "/clients/tami-client-logo",
    link: "#tami",
  },
  {
    name: "Central Park",
    image: "/clients/central-park-client-logo",
    link: "#central-park",
  },
  {
    name: "BuyMe",
    image: "/clients/buyme-logo-client",
    link: "#buyme",
  },
  {
    name: "Toyota",
    image: "/clients/toyota",
    link: "#toyota",
  },
  {
    name: "Geely Auto",
    image: "/clients/geely",
    link: "#geely",
  },
  {
    name: "Eldan",
    image: "/clients/eldan-logo-client",
    link: "#eldan",
  },
  {
    name: "Coca Cola",
    image: "/clients/coca-cola--logo-clients",
    link: "#coca-cola",
  },
  {
    name: "Histadrut",
    image: "/clients/histadrut-logo-client",
    link: "#histadrut",
  },
  {
    name: "McDonalds",
    image: "/clients/mcdonalds",
    link: "#mcdonalds",
  },
  {
    name: "HFD",
    image: "/clients/hfd",
    link: "#hfd",
  },
  {
    name: "GT",
    image: "/clients/gt",
    link: "#gt",
  },
  {
    name: "Meitav",
    image: "/clients/meitav",
    link: "#meitav",
  },
  {
    name: "Mazda",
    image: "/clients/mazda-logo-clients",
    link: "#mazda",
  },
  {
    name: "Super-Pharm",
    image: "/clients/sp-logo-client",
    link: "#super-pharm",
  },
  { name: "TLV Mall", image: "/clients/tlv-logo-client" },
  { name: "Hapoalim", image: "/clients/hapoalim-bank" },
  { name: "Harel", image: "/clients/harel-logo-client" },
  { name: "Ford", image: "/clients/ford-logo-client" },
  { name: "CarWiz", image: "/clients/carwiz" },
  {
    name: "BMW",
    image: "/clients/bmw",
    link: "#bmw",
  },
  {
    name: "Asuta",
    image: "/clients/asuta",
    link: "#asuta",
  },
  { name: "PowerCard", image: "/clients/powercard-logo-client" },
  {
    name: "Shikui Binui",
    image: "/clients/shikui-binui",
    link: "#shikui-binui",
  },
  {
    name: "Astrum",
    image: "/clients/astrum",
    link: "#astrum",
  },
  {
    name: "Golan",
    image: "/clients/golan",
    link: "#golan",
  },
  {
    name: "Hot",
    image: "/clients/hot",
    link: "#hot",
  },
  {
    name: "Adata",
    image: "/clients/adata",
    link: "#adata",
  },
]

/*

{
  name: "",
  image: "/clients/",
  link: "#",
},

*/

/*
export const clients: Client[] = [
  {
    name: "Coca-Cola",
    image: "/clients/coca-cola--logo-clients",
    link: "#coca-cola",
  },
  {
    name: "Strauss",
    image: "/clients/strauss--logo-clients",
    link: "#strauss",
  },
  { name: "Cellcom", image: "/cellcom/logo", link: "#cellcom" },
  { name: "mazda", image: "/clients/mazda-logo-clients", link: "#mazda" },
  { name: "yellow", image: "/clients/yellow-logo-clients", link: "#yellow" },
  {
    name: "Super-Pharm",
    image: "/clients/sp-logo-client",
    link: "#super-pharm",
  },
  { name: "Israel Hayom", image: "/clients/israel-hyom-logo-client" },
  { name: "Harel", image: "/clients/harel-logo-client" },
  { name: "Eldan", image: "/clients/eldan-logo-client" },
  { name: "Ford", image: "/clients/ford-logo-client" },
  {
    name: "Histadrut",
    image: "/clients/histadrut-logo-client",
    link: "#histadrut",
  },
  { name: "BuyMe", image: "/clients/buyme-logo-client" },
  { name: "PowerCard", image: "/clients/powercard-logo-client" },
  { name: "TLV Mall", image: "/clients/tlv-logo-client" },
  { name: "All In", image: "/clients/allin-logo-client" },
  {
    name: "Golan",
    image: "/clients/golan-logo-client",
    link: "#golan",
  },
  {
    name: "GTWork",
    image: "/clients/strauss--logo-clients",
    link: "#strauss",
  },
  { name: "MacDonalds", image: "/cellcom/logo", link: "#cellcom" },
  { name: "Amot", image: "/clients/mazda-logo-clients", link: "#mazda" },
  { name: "yellow", image: "/clients/yellow-logo-clients", link: "#yellow" },
  {
    name: "Toyota",
    image: "/clients/sp-logo-client",
    link: "#super-pharm",
  },
  { name: "CarWiz", image: "/carwiz/carwiz_logo" },
  { name: "Central Park", image: "/clients/harel-logo-client" },
  { name: "Tami 4", image: "/clients/eldan-logo-client" },
  { name: "HFD", image: "/clients/ford-logo-client" },
  {
    name: "BMW",
    image: "/clients/histadrut-logo-client",
    link: "#histadrut",
  },
  { name: "Geely", image: "/clients/buyme-logo-client" },
  { name: "Hapoalim", image: "/clients/powercard-logo-client" },
  { name: "GDATA", image: "/clients/tlv-logo-client" },
  { name: "Meitav", image: "/clients/allin-logo-client" },
]

*/
