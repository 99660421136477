import { Typography } from "@material-ui/core"
import { ColoredBackgroundCircle } from "common/ColoredBackgroundCircle"
import { GlobalContext } from "common/GlobalContext"
import NextSectionButton from "common/icons/NextSectionButton"
import { Desktop, useIsDesktop } from "common/MediaQueries"
import HeroHomepage from "Components/Sections/heroContent/HeroHomepage"
import SectionClients from "Components/Sections/SectionClients"
import SectionContact from "Components/Sections/SectionContact"
import SectionHero from "Components/Sections/SectionHero"
import SectionOurEmployees from "Components/Sections/SectionOurEmployees"
import SectionProjects from "Components/Sections/SectionProjects"
import { ProgressBarColors, sections } from "Components/Sections/sections"
import SectionServices from "Components/Sections/SectionServices"
import { NextSeo } from "next-seo"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import SwiperCore, {
  EffectFade,
  HashNavigation,
  Keyboard,
  Mousewheel,
} from "swiper"
import "swiper/css/bundle"
import { Swiper, SwiperSlide } from "swiper/react"

const Styled = {
  Main: styled.main`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 1;
  `,
  Swiper: styled(Swiper)`
    width: 100%;
    height: 100%;
    z-index: 10;
  `,
  NextSectionButton: styled(NextSectionButton)`
    width: 43px;
    height: 43px;
    position: fixed;
    bottom: 15px;
    left: calc(50% - 21.5px);
    z-index: 3;
    ${(p) => p.theme.breakpoints.up("md")} {
      bottom: 20px;
      width: 60px;
      height: 60px;
      left: calc(50% - 30px);

      @media (max-height: 650px) {
        width: 50px;
        height: 50px;
        bottom: 40px;
      }
    }
  `,
  ProgressBarContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    left: 96.5px;
    bottom: 52.5px;
    height: 300px;
    z-index: 3;

    ${(p) => p.theme.breakpoints.up("md")} {
      @media (max-height: 650px) and (max-width: 1100px) {
        left: 65px;
      }

      @media (max-width: 1150px) {
        left: 65px;
      }
    }
  `,
  ProgressBarHeading: styled(Typography)<{ colors: ProgressBarColors }>`
    font-size: 16px;
    color: ${(p) => p.colors.activeColor};
    transform: rotate(-90deg);
    transform-origin: left;
  `,
  ProgressBarStep: styled.div<{
    colors: ProgressBarColors
    activeStep: boolean
  }>`
    width: 2px;
    height: 22px;
    background-color: ${(p) =>
      p.activeStep ? p.colors.activeColor : p.colors.inactiveColor};
  `,
}

const Home = () => {
  const { setActiveSectionIndex, activeSection, setPrev } =
    useContext(GlobalContext)
  const [active, setActive] = useState(0)
  const [swiperController, setSwiperController] = useState<SwiperCore>(null)
  useEffect(() => {
    setActiveSectionIndex(active)
  }, [active, setActiveSectionIndex])
  const isDesktop = useIsDesktop()
  const numberOfSections = Array.from(Array(sections.length).keys())

  return (
    <>
      <NextSeo
        title="mint"
        description="At Publicis mint we develop leading-edge brand utility ideas for clients across the globeOur office is located in Tel Aviv"
        openGraph={{
          title: "mint",
          description:
            "At Publicis mint we develop leading-edge brand utility ideas for clients across the globeOur office is located in Tel Aviv",
          //   images: [
          //     { url: firstImageUrl + "?width=1200", width: 1200, height: 675 },
          //     { url: firstImageUrl + "?width=256", width: 256, height: 144 },
          //   ],
        }}
      />
      <Styled.Swiper
        modules={[EffectFade, Mousewheel, Keyboard, HashNavigation]}
        direction="vertical"
        speed={0}
        slidesPerView={1}
        effect="fade"
        mousewheel
        touchEventsTarget="container"
        keyboard
        hashNavigation={{ replaceState: true, watchState: true }}
        onSlideChange={(swiper) => {
          setPrev(swiper.previousIndex)
          setActive(swiper.activeIndex)
        }}
        onTouchStart={(swiper) => {
          console.log("onTouchStart", swiper)
        }}
        onSwiper={setSwiperController}
      >
        {sections.map((section, index) => (
          <SwiperSlide key={index} data-hash={section.hash}></SwiperSlide>
        ))}
        <span slot="container-end">
          <Styled.Main>
            {isDesktop ? (
              <ColoredBackgroundCircle {...activeSection.background[1]} />
            ) : (
              <ColoredBackgroundCircle {...activeSection.background[0]} />
            )}

            <SectionHero position={0}>
              <HeroHomepage />
            </SectionHero>
            <>
              <SectionServices position={1} />
              <SectionClients position={2} />
              <SectionProjects position={3} />
              <SectionOurEmployees position={sections.length - 2} />
              <SectionContact position={sections.length - 1} />
            </>
            <Desktop>
              <Styled.ProgressBarContainer>
                <Styled.ProgressBarHeading
                  colors={activeSection.progressBarColors}
                >
                  {activeSection.progressBarText}
                </Styled.ProgressBarHeading>

                {numberOfSections.map((index) => (
                  <Styled.ProgressBarStep
                    key={index}
                    activeStep={index <= active}
                    colors={activeSection.progressBarColors}
                  />
                ))}
              </Styled.ProgressBarContainer>
            </Desktop>
            {active !== sections.length - 1 && (
              <Styled.NextSectionButton
                tabIndex={0}
                role="button"
                onClick={() => swiperController?.slideNext()}
              />
            )}
          </Styled.Main>
        </span>
      </Styled.Swiper>
    </>
  )
}

export default Home
