import { Section, sections } from "Components/Sections/sections"
import { FC, useContext } from "react"
import { CSSTransition } from "react-transition-group"
import { css } from "styled-components"
import { transition, transitionTime } from "theme/shared"
import { GlobalContext } from "./GlobalContext"
import { SectionProps } from "./SectionProps"

export const TRANSITION_CLASS = "transitionClass"

type ProjectsCssTransitionProps = SectionProps & {
  enableSectionIn?: boolean
  activeSection: (section: Section) => JSX.Element
}

const ProjectsCssTransition: FC<ProjectsCssTransitionProps> = ({
  activeSection,
  position,
}) => {
  const { active } = useContext(GlobalContext)

  return (
    <>
      {sections.map((section, index) => {
        if (index < position) {
          return null
        }
        return (
          <CSSTransition
            in={active === index}
            appear={active === index}
            timeout={transitionTime}
            classNames={TRANSITION_CLASS}
            unmountOnExit
            mountOnEnter
            key={index}
          >
            {activeSection(section)}
          </CSSTransition>
        )
      })}
    </>
  )
}

export default ProjectsCssTransition

export const fadeTransition = css`
  &.${TRANSITION_CLASS}-enter {
    opacity: 0;
    z-index: 1;
  }

  &.${TRANSITION_CLASS}-enter-active {
    opacity: 1;
    ${transition};
  }

  &.${TRANSITION_CLASS}-exit {
    opacity: 1;
  }

  &.${TRANSITION_CLASS}-exit-active {
    opacity: 0;
    ${transition};
  }
`
